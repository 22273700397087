import styled from 'styled-components';

const PopupStyle = styled.div`
  --popupMaxHeight: 100vh;
  --popupContentMaxHeight: 100%;
  --popupMaxWidth: var(--sp139x);
  --popupPadTop: var(--sp19x);
  --popupPadBot: var(--sp13x);
  --popupPadRL: var(--sp13x);
  --closeSize: var(--sp9x);
  --closePosTopSize: var(--sp6x);
  --closePosRightSize: var(--sp6x);
  --popupBorderRadius: var(--sp4x);

  position: fixed;
  width: 100vw;
  inset: 0;
  z-index: 100000;

  color: #fff;
  .eapps-social-share-buttons-position-left
    .eapps-social-share-buttons-container {
    transform: none;
  }
  .description {
    white-space: pre-line;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &.popup-white {
    color: var(--royalBlue1000);
    .popup-content-container .popup-content-wrap {
      background: #edebe7;
    }
    .popup-content-container .popup-content-wrap .close-wrap {
      border: 0;
      i {
        color: #0d1724;
      }
    }
  }
  .text-center {
    text-align: center;
  }
  .title {
    margin-bottom: 24px;
  }
  .button-wrapper {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-wrapper .file-section {
    --fileHeight: 80%;
    margin-top: 80px;
  }
  .popup-background {
    background-color: #000;
    opacity: 40%;
    pointer-events: all;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.hide {
      opacity: 0;
    }
  }

  .popup-content-container {
    max-width: var(--popupMaxWidth);
    max-height: var(--popupMaxHeight);
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: all;
    overflow: scroll;
    margin: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .popup-content-wrap {
      position: relative;
      width: 100%;
      height: fit-content;
      max-height: var(--popupContentMaxHeight);
      padding: var(--popupPadTop) var(--popupPadRL) var(--popupPadBot)
        var(--popupPadRL);
      background: var(--royalBlue1000);
      box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.1);
      opacity: 1;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border-radius: var(--popupBorderRadius);

      &.fixed {
        position: fixed;
        left: min(var(--posX), calc(100% - 300px));
        top: max(var(--posY), 200px);
        transform: translateX(-50%) translateY(calc(-100% - 24px));
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .close-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: var(--closeSize);
        height: var(--closeSize);
        top: var(--closePosTopSize);
        right: var(--closePosRightSize);
        z-index: 2;
        border: 0 solid var(--white1000);
        border-radius: var(--sp1x);
        cursor: pointer;

        i {
          font-size: var(--p3);
          color: var(--white1000);
        }
      }
    }
  }

  &.popup-paStyle .popup-content-wrap {
    padding: 0;
    background: #fff;
    color: #000430;
    width: fit-content;
    transition: margin-bottom 0.25s;
  }

  &.popup-paStyle .popup-content-container {
    justify-content: center;
  }

  .popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #d0d4dc;
  }

  .popup-header .font-argent {
    font-size: 22px;
    font-weight: 800;
    line-height: 120%;
    color: #001731;
    font-family: var(--urbanist);
  }

  .popup-header .icon {
    border-radius: 50%;
    background: var(--light200);
    padding: 4px;
    cursor: pointer;
  }

  //! 1920
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeL}) {
    --popupMaxWidth: var(--sp103x);
    --popupPadTop: var(--sp14x);
    --popupPadBot: var(--sp10x);
    --popupPadRL: var(--sp10x);
    --closeSize: var(--sp7x);
    --closePosTopSize: var(--sp5x);
    --closePosRightSize: var(--sp5x);
    --popupBorderRadius: var(--sp3x);
  }

  //! 1536
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeM}) {
    --popupMaxWidth: var(--sp103x);
    --popupPadTop: var(--sp14x);
    --popupPadBot: var(--sp10x);
    --popupPadRL: var(--sp10x);
    --closeSize: var(--sp7x);
    --closePosTopSize: var(--sp5x);
    --closePosRightSize: var(--sp5x);
    --popupBorderRadius: var(--sp3x);
  }

  //! 1366
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeS}) {
    --popupMaxWidth: var(--sp103x);
    --popupPadTop: var(--sp12x);
    --popupPadBot: var(--sp8x);
    --popupPadRL: var(--sp8x);
    --closeSize: var(--sp6x);
    --closePosTopSize: var(--sp4x);
    --closePosRightSize: var(--sp4x);
    --popupBorderRadius: var(--sp3x);
  }

  //! 1280
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.tabletSize}) {
    --popupMaxWidth: var(--sp83x);
    --popupPadTop: var(--sp11x);
    --popupPadBot: var(--sp7x);
    --popupPadRL: var(--sp7x);
    --closeSize: var(--sp6x);
    --closePosTopSize: var(--sp3x);
    --closePosRightSize: var(--sp3x);
    --popupBorderRadius: var(--sp3x);
  }

  //! 768
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeS}) {
    --popupMaxWidth: var(--sp83x);
    --popupPadTop: var(--sp11x);
    --popupPadBot: var(--sp6x);
    --popupPadRL: var(--sp6x);
    --closeSize: var(--sp6x);
    --closePosTopSize: var(--sp3x);
    --closePosRightSize: var(--sp3x);
    --popupBorderRadius: var(--sp3x);
  }

  //! Mobile
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeSMin}) {
    --popupMaxWidth: calc(100% - var(--sp4x));
    --popupPadTop: var(--sp9x);
    --popupPadBot: var(--sp3x);
    --popupPadRL: var(--sp3x);
    --closeSize: var(--sp5x);
    --closePosTopSize: var(--sp2x);
    --closePosRightSize: var(--sp2x);
    --popupBorderRadius: var(--sp2x);

    &.popup-paStyle .popup-content-wrap {
      align-self: flex-end;
      margin-block-end: max(env(keyboard-inset-height, 24px), 24px);
      overflow: hidden;
      max-height: calc(100% - 32px);

      &.fixKb {
        margin-bottom: 250px;
      }
    }

    .popup-content-container .popup-content-wrap.fixed {
      position: relative;
      left: unset;
      top: unset;
      transform: unset;
    }

    .popup-background.hide {
      opacity: 0.4;
    }
  }
`;
export default PopupStyle;
