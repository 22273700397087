'use client';

import { pushDataLayer } from '@/helpers';
import { PropsWithChildren, useEffect, useRef } from 'react';

function ScrollWrapper({ children }: PropsWithChildren) {
  //! Refs
  const main = useRef<HTMLDivElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const fn = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        pushDataLayer({
          event: 'page_scroll',
        });
      }, 500);
    };
    addEventListener('scroll', fn);

    return () => {
      removeEventListener('scroll', fn);
    };
  }, []);

  return (
    <div ref={main} id="smooth-wrapper">
      <div id="smooth-content">{children}</div>
    </div>
  );
}

export default ScrollWrapper;
